<template>
    <div>
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="hasError"
      >
        <h4 class="alert-heading">
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_data') }}
        </h4>
      </b-alert>
      <template v-if="elementData">
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="FileTextIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="font-weight-bold">
                {{ $t('fullInvoice.section.edit') }} {{ elementData.number }}
              </span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" xl="6">
                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('fullInvoice.section.basic_info') }}</b>
                </h3>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.number') }}</th>
                    <td class="pb-50">{{ elementData.number }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.arl') }}</th>
                    <td class="pb-50">{{ elementData.arl.name }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.nit') }}</th>
                    <td class="pb-50">{{ elementData.arl.nit }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.date') }}</th>
                    <td class="pb-50">{{ formatDate(elementData.date) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.arl_filed_date') }}</th>
                    <td class="pb-50">{{ formatDate(elementData.arl_filed_date) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.arl_filed_number') }}</th>
                    <td class="pb-50">{{ elementData.arl_filed_number }}</td>
                  </tr>
                </table>
              </b-col>
              <b-col cols="12" xl="6">
                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('fullInvoice.section.financial_info') }}</b>
                </h3>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.value') }}</th>
                    <td class="pb-50">{{ formatCurrency(elementData.value) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.iva') }}</th>
                    <td class="pb-50">{{ formatCurrency(elementData.iva) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.charged_value') }}</th>
                    <td class="pb-50">{{ formatCurrency(elementData.charged_value) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.fuente') }}</th>
                    <td class="pb-50">{{ formatCurrency(elementData.fuente) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.ica') }}</th>
                    <td class="pb-50">{{ formatCurrency(elementData.ica) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.other_taxes') }}</th>
                    <td class="pb-50">{{ formatCurrency(elementData.other_taxes) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.neto') }}</th>
                    <td class="pb-50">{{ formatCurrency(elementData.net_value) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.received_value') }}</th>
                    <td class="pb-50">{{ formatCurrency(elementData.received_value) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.balance') }}</th>
                    <td class="pb-50">{{ formatCurrency(elementData.balance) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.pay_date') }}</th>
                    <td class="pb-50">{{ formatDate(elementData.pay_date) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.credit') }}</th>
                    <td class="pb-50">{{ formatCurrency(elementData.credit) }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.status') }}</th>
                    <td class="pb-50">{{ elementData.billing_status_id }}</td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('fullInvoice.object.observations') }}</b>
                </h3>
                <p>{{ elementData.observations }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('fullInvoice.section.patient_info') }}</b>
                </h3>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.patinet.document_type') }}</th>
                    <td class="pb-50">{{ patient.document_type.value }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.patinet.identity') }}</th>
                    <td class="pb-50">{{ patient.identity }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.patinet.file_number') }}</th>
                    <td class="pb-50">{{ patient.identity }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">{{ $t('fullInvoice.object.patinet.full_name') }}</th>
                    <td class="pb-50">{{ `${patient.first_name} ${patient.second_name} ${patient.first_surname} ${patient.second_surname}` }}</td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('fullInvoice.object.services') }}</b>
                </h3>
                <div v-for="invoice in invoiceItems" :key="invoice.id" class="mb-1">
                  {{ getFormattedData(invoice) }}
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button
                  variant="primary"
                  @click="$router.go(-1)"
                >
                  {{ $t('actions.back') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </template>
    </div>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BAlert,
  } from 'bootstrap-vue'
  import store from '@/store'
  import router from '@/router'
  import moment from 'moment'
  import ElementStoreModule from '../storeModule'
  
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardBody,
      BRow,
      BCol,
      BButton,
      BAlert,
    },
    data() {
      return {
        elementData: null,
        patient: {
          document_type: {
            value: undefined,
          },
        },
        invoiceItems: [],
        RESOURCES_ELEMENT_NAME: 'fullInvoice',
        ELEMENT_APP_STORE_MODULE_NAME: '',
        hasError: false,
      }
    },
    mounted() {
      this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`
      if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) {
        store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)
      }
      this.fetchElement()
    },
    unmounted() {
      if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
      }
    },
    methods: {
      fetchElement() {
        store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElement`, { id: router.currentRoute.params.id })
          .then(response => {
            this.elementData = response.data
            this.fetchPatient(this.elementData.patient_id)
            this.fetchInvoiceItems(this.elementData.id)
          })
          .catch(() => {
            this.hasError = true
          })
      },
      fetchPatient(patientId) {
        store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_patient`, { id: patientId })
          .then(response => {
            this.patient = response.data
          })
          .catch(error => {
            console.error('Error fetching patient:', error)
          })
      },
      fetchInvoiceItems(invoiceId) {
        store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchInvoiceItems`, { id: invoiceId })
          .then(response => {
            this.invoiceItems = response.data.items
          })
          .catch(error => {
            console.error('Error fetching invoice items:', error)
          })
      },
      formatDate(date) {
        if (!date) return '';
        const formattedDate = moment(date).format('DD/MM/YYYY');
        return formattedDate !== 'Invalid date' ? formattedDate : ''; 
      },
      formatCurrency(value) {
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value)
      },
      getFormattedData(invoice) {
        return `${invoice.cups} - ${invoice.service_data.description} - Fecha: ${
          this.formatDate(invoice.service_date)} - Cod. Servicio: ${invoice.authorization}`
      },
    },
  }
  </script>